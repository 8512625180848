import { ref } from 'vue'
let countdown = '' // 用于显示倒计时
let timerId = null

// 计算剩余时间的函数
export const calculateTimeDifference = (expirationTime) => {
  const currentTime = new Date().getTime()
  const timeDifference = expirationTime - currentTime
  countdown = formattedCountdown(timeDifference)
  return countdown
  // if (timeDifference > 0) {
  //   timerId = requestAnimationFrame(calculateTimeDifference) // 递归调用 requestAnimationFrame
  // }
}

// 计算倒计时的计算属性
const formattedCountdown = (currentTime, format = '') => {
  const timeDifference = currentTime
  if (timeDifference > 0) {
    const Day = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    const Hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const Minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))
    const Seconds = Math.floor((timeDifference % (1000 * 60)) / 1000)
    if (format) {
      format = format.toUpperCase()
      format = format.replace('DD', Day)
      format = format.replace('HH', Hours)
      format = format.replace('MI', Minutes)
      format = format.replace('SS', Seconds)
      return format
    }
    return `${Day}d ${Hours}h ${Minutes}m ${Seconds}s`
  }
  return 'Order closed'
}

// 计算时间差并设置定时器
export const countdownTime = (time, callback, format = '') => {
  const expirationTime = new Date(time).getTime()
  const currentTime = new Date().getTime()
  const timeDifference = expirationTime - currentTime

  if (timeDifference > 0) {
    timerId = setInterval(() => {
      const remainingTime = new Date(expirationTime - new Date().getTime()).getTime()
      if (remainingTime <= 0) {
        clearInterval(timerId)
        callback('Order closed')
      } else {
        const formattedTime = formattedCountdown(remainingTime, format)
        callback(formattedTime)
      }
    }, 1000)
  } else {
    callback('Order closed')
  }
}

// 是否属于当天
export const isSameDay = (dateToCheck) => {
  const today = new Date()
  const inputDate = new Date(dateToCheck)

  return today.getFullYear() === inputDate.getFullYear() && today.getMonth() === inputDate.getMonth() && today.getDate() === inputDate.getDate()
}

// 判定是否大于当天
export const isDateGreaterThanToday = (dateToCheck) => {
  const today = new Date()
  const inputDate = new Date(dateToCheck)

  // 将日期转换为毫秒数来比较
  const todayTimestamp = today.setHours(0, 0, 0, 0)
  const inputDateTimestamp = inputDate.setHours(0, 0, 0, 0)

  return inputDateTimestamp > todayTimestamp
}
