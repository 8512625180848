<!-- 订单成功页 -->
<script setup>
  import PickProduct from '@/views/index/PickProduct'
  import sucItem from './compontent/suc-item'
  import paySuc from './compontent/pay-suc'
  import payError from './compontent/pay-error'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const router = useRouter()
  const { userStore } = useStore()
  const { proxy } = getCurrentInstance()

  const data = reactive({
    loading: false,
    paymentType: '', // 支付方式
    orderId: '', // 总订单no
    orderInfo: {}, // 订单详情
    bankAccountInfo: {} // 转账信息
  })

  // 查询订单详情
  const getOrderDetail = () => {
    data.loading = true
    api.order
      .ordersDetail({
        mainOrderNo: data.orderId
      })
      .then((res) => {
        console.log('订单详情', res)
        if (res && res.subOrders) {
          data.orderInfo = res
        }
      })
      .catch((error) => {
        proxy.$Message.confirm({
          title: `${proxy.$t('Notice')}`,
          msg: error.title ? `${error.title}` : 'Network error, please refresh and try again'
        })
      })
      .finally(() => {
        data.loading = false
      })
  }

  const getBankAccount = () => {
    api.pay.getBankAccount().then((res) => {
      console.log(res, 'getBankAccount')
      data.bankAccountInfo = res
    })
  }

  const init = () => {
    getOrderDetail()
    if (data.paymentType === 'BANK_TRANSFER') {
      getBankAccount()
    }
  }

  onMounted(() => {
    // console.log(useRouter(),  'this.$router')
    // console.log(window.history, '过window.history')

    const query = useRoute().query
    data.orderId = query.orderId || ''
    data.paymentType = query.paymentType || ''
    if (data.orderId) {
      init()
    } else {
      // router.replace({ path: '/' })
    }
  })
</script>

<template>
  <div class="page-content" v-loading="data.loading">
    <div class="content">
      <div class="pay_suc_card" v-if="data.orderInfo.mainOrderNo">
        <!-- <suc-item :order="data.orderInfo" :bankAccountInfo="data.bankAccountInfo"></suc-item> -->
        <div
          v-if="
            data.orderInfo.orderStatus === 'PAYMENT_FAILED' ||
            data.orderInfo.orderStatus === 'PAYING' ||
            data.orderInfo.orderStatus === 'NOPAID' ||
            data.orderInfo.orderStatus === 'CLOSED'
          "
        >
          <pay-error :order="data.orderInfo"></pay-error>
        </div>
        <div v-else>
          <pay-suc :order="data.orderInfo"></pay-suc>
        </div>
      </div>
      <PickProduct />
    </div>
  </div>
</template>

<style lang="less" scoped>
  .page-content {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 400px;
    margin: 0 auto;
    background-color: #f4f4f4;
  }
  .content {
    width: var(--minWidth);
    margin: 0 auto;
  }
  .pay_suc_card {
    width: 100%;
    background: #fff;
    margin: 15px 0;
  }
</style>
