<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()
  const props = defineProps({
    order: {
      type: Object
    }
  })

  const goHome = () => {
    proxy.$router.push({
      path: '/'
    })
  }

  const toOrderMain = () => {
    proxy.$router.push({
      name: 'ORDERMAIN',
      params: {
        orderId: props.order.mainOrderNo
      }
    })
  }

  const goHelpCenter = () => {
    userStore.setUserShowPage({ page: 'userHelp', selectType: 'userHelp' })
    proxy.$router.push({
      path: '/USERINFO'
    })
  }
</script>

<template>
  <div class="status-box rowSS">
    <div class="suc-ico"></div>
    <div class="right-box">
      <div class="rowSC">
        <p class="status-title fw500">Payment Pending</p>
        <div class="su-line"></div>
        <p>
          <span class="fw500">Order No. </span>
          {{ props.order.mainOrderNo }}
        </p>
      </div>
      <p class="hint-text">We encountered a payment error. The reason can be as followed</p>
      <ul class="mt10 ml20 text-li">
        <li>Cancelled by the user</li>
        <li>Insufficient balance</li>
        <li>System Error</li>
      </ul>
      <p class="hint-text mt10">Need more info? <span class="contact-us" @click="goHelpCenter">Contact us</span></p>
      <div class="rowSS btn-box">
        <div class="hr-primary-btn btn rowCC ml10" @click="toOrderMain">Order</div>
        <div class="btn home-btn rowCC" @click="goHome">Home Page</div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .status-box {
    font-size: 20px;
    padding: 20px 60px;
    display: grid;
    grid-template-columns: 76px 1fr;
    .suc-ico {
      width: 76px;
      height: 76px;
      background: url('https://cdn.pricoo.pk/3086608b.png') no-repeat;
      background-size: 100% 100%;
    }
    .right-box {
      margin-left: 30px;
      color: rgba(34, 34, 34, 0.8);
      .status-title {
        font-size: 24px;
        margin-bottom: 5px;
      }
      .text-li {
        li {
          margin-left: 20px;
          list-style: disc;
        }
      }
      .contact-us {
        color: rgba(12, 92, 168, 1);
        cursor: pointer;
        text-decoration: underline;
      }
      .hint-text {
        color: rgba(34, 34, 34, 0.8);
      }
      .fw500 {
        font-weight: 500;
        color: var(--btnBgColor);
      }
      .btn-box {
        flex-direction: row-reverse;
        .btn {
          width: fit-content;
          padding: 6px 20px;
          border-radius: 24px;
          font-size: 16px;
          cursor: pointer;
        }
        .home-btn {
          height: calc(100% -2px);
          border: 1px solid var(--btnBgColor);
        }
      }
    }
    .su-line {
      width: 1px;
      height: 30px;
      margin: 0 10px;
      background-color: rgba(0, 0, 0, 0.18);
    }
  }
</style>
